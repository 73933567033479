// トップスライダーの起動 // Launch the top slider


//実績ロゴスライダー
const swiperLogo = new Swiper(".js-achive-slide", {
  speed: 1000, // スライド変化時間
  loop: true,
  slidesPerView: 3.2, 
  speed: 3000, // ループの時間
  allowTouchMove: false, // スワイプ無効
  autoplay: {
    delay: 0, // 途切れなくループ
  },
  breakpoints: {
    569: {
      slidesPerView: 5, 
      speed: 6000, 
    },
    769: {
      slidesPerView: 7, 
    }
  }
});